import React, { useState, useEffect } from "react";
import useTranslation from "next-translate/useTranslation";

export default function FeatureComparison() {
    const { t, lang } = useTranslation("home");

    // Add mobile state detection
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const mq = window.matchMedia("(max-width: 768px)");
        setIsMobile(mq.matches);
        const handleMq = (e) => setIsMobile(e.matches);
        mq.addEventListener("change", handleMq);
        return () => mq.removeEventListener("change", handleMq);
    }, []);

    // Retrieve header texts from translations
    const headers = {
        features_desktop: t("table.headers.features_desktop"),
        features_mobile: t("table.headers.features_mobile"),
        instaswap: t("table.headers.instaswap"),
        typical_dex: t("table.headers.typical_dex", { DEX: "DEX" }),
        typical_cex: t("table.headers.typical_cex")
    };

    // Retrieve row texts from translations
    const rows = [
        {
            feature: t("table.rows.cross_chain_swaps", { blockchains: "Blockchains", cross: "cross", Blockchain: "Blockchain", blockchain: "blockchain", non: "Non", chain: "Chain", custodial: "Custodial", multi: "multi", DEX: "DEX", Cross: "Cross", Chain: "Chain" }),
            instaswap: t("table.rows.cross_chain_swaps_instaswap"),
            typical_dex: t("table.rows.cross_chain_swaps_dex"),
            typical_cex: t("table.rows.cross_chain_swaps_cex")
        },
        {
            feature: t("table.rows.supported_chains"),
            instaswap: t("table.rows.supported_chains_instaswap"),
            typical_dex: t("table.rows.supported_chains_dex"),
            typical_cex: t("table.rows.supported_chains_cex")
        },
        {
            feature: t("table.rows.liquidity_sources"),
            instaswap: t("table.rows.liquidity_sources_instaswap", { DEXs: "DEXs" }),
            typical_dex: t("table.rows.liquidity_sources_dex", { DEXs: "DEXs" }),
            typical_cex: t("table.rows.liquidity_sources_cex")
        },
        {
            feature: t("table.rows.best_pricing_guarantee"),
            instaswap: t("table.rows.best_pricing_guarantee_instaswap"),
            typical_dex: t("table.rows.best_pricing_guarantee_dex"),
            typical_cex: t("table.rows.best_pricing_guarantee_cex")
        },
        {
            feature: t("table.rows.no_account_required"),
            instaswap: t("table.rows.no_account_required_instaswap"),
            typical_dex: t("table.rows.no_account_required_dex"),
            typical_cex: t("table.rows.no_account_required_cex")
        },
        {
            feature: t("table.rows.walletless_transactions"),
            instaswap: t("table.rows.walletless_transactions_instaswap"),
            typical_dex: t("table.rows.walletless_transactions_dex"),
            typical_cex: t("table.rows.walletless_transactions_cex")
        },
        {
            feature: t("table.rows.gas_fees_transparency"),
            instaswap: t("table.rows.gas_fees_transparency_instaswap"),
            typical_dex: t("table.rows.gas_fees_transparency_dex"),
            typical_cex: t("table.rows.gas_fees_transparency_cex")
        },
        {
            feature: t("table.rows.support"),
            instaswap: t("table.rows.support_instaswap"),
            typical_dex: t("table.rows.support_dex"),
            typical_cex: t("table.rows.support_cex")
        },
        {
            feature: t("table.rows.single_ui"),
            instaswap: t("table.rows.single_ui_instaswap"),
            typical_dex: t("table.rows.single_ui_dex"),
            typical_cex: t("table.rows.single_ui_cex")
        }
    ];

    return (
        <section className="feature-comparison padding-bottom">
            <div className="container" style={isMobile ? { maxWidth: '287px' } : {}}>
                <div className="table-responsive">
                    <table className="feature-table">
                        <thead>
                            <tr>
                                <th>
                                    <span className="desktop-label">{headers.features_desktop}</span>
                                    <span className="mobile-label">{headers.features_mobile}</span>
                                </th>
                                <th className="highlight">{headers.instaswap}</th>
                                <th>{headers.typical_dex}</th>
                                <th>{headers.typical_cex}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td
                                        data-label-desktop={headers.features_desktop}
                                        data-label-mobile={headers.features_mobile}
                                    >
                                        {row.feature}
                                    </td>
                                    <td className="highlight" data-label="Instaswap">
                                        {row.instaswap}
                                    </td>
                                    <td data-label="Typical DEX">
                                        {row.typical_dex}
                                    </td>
                                    <td data-label="Typical CEX">
                                        {row.typical_cex}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section >
    );
}
