/* eslint-disable react/no-unescaped-entities */
import useTranslation from "next-translate/useTranslation";

function ServicesStats({ pageStats }) {


    const { t } = useTranslation("home");

    // Use API values if available; otherwise, create empty objects for loader placeholders
    const stats = pageStats?.response?.stats ? [
        { amount: pageStats.response.stats[0].value, labelKey: "inNumbers.stat1Label" },
        { amount: pageStats.response.stats[1].value, labelKey: "inNumbers.stat2Label" },
        { amount: pageStats.response.stats[4].value, labelKey: "inNumbers.stat3Label" },
        { amount: pageStats.response.stats[3].value, labelKey: "inNumbers.stat4Label" }
    ] : [{}, {}, {}, {}];

    return (
        <section className="service padding-top banner--style47" style={{ paddingBottom: "60px" }}>

            {/* Title & Subheading */}
            <div className="section-header section-header--max50">
                <h2 className="mb-15 mt-minus-5">
                    {t("inNumbers.sectionTitle")}
                </h2>
                <p>{t("inNumbers.sectionSubtitle")}</p>
            </div>

            <div className="container">
                <div className="service__wrapper">
                    <div className="row g-4 align-items-center">
                        {/* Map over our array of stats */}
                        {stats.map((item, index) => (
                            <div className="col-sm-6 col-md-6 col-lg-3" key={index}>
                                <div
                                    className="service__item service__item--style1"
                                    data-aos="fade-up"
                                    data-aos-duration={800 + 200 * index} // slight stagger effect
                                >
                                    <div className="service__item-inner text-center">
                                        <h2 className="mb-1">
                                            {item.amount ? item.amount : <div className="loader">Loading...</div>}
                                        </h2>
                                        <p className="mb-0">
                                            {t(item.labelKey, { Blockchains: "Blockchains", Multi: "Multi", Blockchain: "Blockchain", DEX: "DEX" })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* End of stats map */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesStats;
