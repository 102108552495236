import { Tab, Nav } from "react-bootstrap";
import Counter from "@/components/base/Counter";
import useTranslation from "next-translate/useTranslation";
import Image from 'next/image';
import getConfig from 'next/config';


function WhyUs() {
  const { t } = useTranslation("home");

  // Instead of pulling data from JSON arrays, define an array of "key references":
  const bulletItems = [
    { titleKey: "whyus.benefit1Title", descKey: "whyus.benefit1Desc" },
    { titleKey: "whyus.benefit5Title", descKey: "whyus.benefit5Desc" },
    { titleKey: "whyus.benefit2Title", descKey: "whyus.benefit2Desc" },
    { titleKey: "whyus.benefit3Title", descKey: "whyus.benefit3Desc" },
    { titleKey: "whyus.benefit4Title", descKey: "whyus.benefit4Desc" }
    // { titleKey: "whyus.benefit6Title", descKey: "whyus.benefit6Desc" }
  ];

  // Hardcoded images & numbers:
  const ratingImages = [
    "images/feature/5.png",
    "images/feature/6.png",
    "images/feature/7.png",
    "images/feature/8.png",
    "images/feature/8.png"
    // "images/feature/8.png"
  ];
  const itemImages = [
    "/images/why-us/swap-native-tokens.png",
    "/images/why-us/cross-chain-swaps.png",
    "/images/why-us/instaswap-dex-walletless-swaps.png",
    "/images/why-us/non-custodial-dex-exchange.png",
    "/images/why-us/user-friendly-crypto-swaps.png"
    // "/images/feature/2.png"
  ];
  const loanAmounts = [10, 18, 30, 28, 28, 28];
  const { publicRuntimeConfig } = getConfig();
  const basePath = publicRuntimeConfig.basePath || '';
  return (
    <section className="feature feature--style1 padding-bottom padding-top">
      <div className="container">
        <div className="feature__wrapper">
          <div className="row g-5 align-items-center justify-content-between">
            <Tab.Container defaultActiveKey={0}>

              {/* LEFT COLUMN */}
              <div className="col-md-6 col-lg-5">
                <div
                  className="feature__content"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <div className="feature__content-inner">
                    <div className="section-header">
                      <h2 className="mb-15 mt-minus-5 text-center text-sm-start">
                        {t("whyus.mainHeading", { Cross: "Cross", Chain: "Chain", Multi: "Multi", Blockchain: "Blockchain" })}
                      </h2>
                      {/* <p className="mb-0">
                        {t("whyus.subtitle")}
                      </p> */}
                    </div>

                    <div className="feature__nav">
                      <Nav variant="pills" className="nav--feature ">
                        {bulletItems.map((item, index) => (
                          <Nav.Link key={index} eventKey={index}>
                            <Nav.Item className="feature__item">
                              <div className="feature__item-inner">
                                <div className="feature__item-content">
                                  <h3 style={{ fontSize: '1rem', marginBottom: '0px', fontWeight: 600, marginInlineStart: '0px', marginInlineEnd: '0px', unicodeBidi: 'isolate', fontFamily: '"Open Sans", sans-serif' }}>{index + 1}. {t(`${item.titleKey}`, { Multi: "Multi", Blockchain: "Blockchain", Non: "Non", Custodial: "Custodial" })}</h3>
                                </div>
                              </div>
                            </Nav.Item>
                          </Nav.Link>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>

              {/* RIGHT COLUMN */}
              <div className="col-md-6 col-lg-6 dis-pd">
                <div
                  className="feature__thumb pt-5 pt-md-0"
                  data-aos="fade-left"
                  data-aos-duration="800"
                >
                  <div className="feature__thumb-inner">
                    <Tab.Content>
                      {bulletItems.map((item, index) => (
                        <Tab.Pane eventKey={index} key={index} className={{ display: "flex" }}>
                          <div className="feature__image floating-content order-2 order-md-1">
                            <Image
                              src={itemImages[index]}
                              alt="Feature image"
                              width='400'
                              height='400'
                            />
                          </div>
                          {/* Show the bullet-point description below */}
                          <div className="order-1 order-md-2" style={{ marginTop: "1rem" }}>
                            <p>{t(item.descKey, { blockchain: "Blockchain" })}</p>
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </div>
                </div>
              </div>

            </Tab.Container>
          </div>
        </div>
      </div >

      <div className="feature__shape">
        <div style={{
          backgroundImage: `url(${basePath}/images/others/bglandscape-stars-dark-small-square-low.webp)`,
          backgroundSize: "auto",
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0px',
          left: '0px',
          opacity: 0.1
        }}></div>
        <span className="feature__shape-item feature__shape-item--1">
          {/* <Image
            width={70}
            height={70}
            src="/images/anim-icons/swap-native-tokens-defi.webm"
            alt="shape-icon"
            loading="lazy"
          /> */}

          <video
              autoPlay
              loop
              muted
              playsInline
              width={70} // or use className="w-[300px]" with Tailwind
              height={70}
              style={{ display: 'block' }}
              >
              <source src="/images/anim-icons/swap-native-tokens-defi.webm" type="video/webm" />
              Your browser does not support the video tag.
          </video>
        </span>

      </div>
    </section >
  );
}

export default WhyUs;
