import { useEffect, useState } from "react";
import Link from "next/link";
import Image from 'next/image';
import "react-loading-skeleton/dist/skeleton.css";
import HeroWidget from './HeroWidget';
import useTranslation from "next-translate/useTranslation";

export default function Hero({ assets, networks }) {
    const { t } = useTranslation("home");

    // New state to track mobile devices
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Update mobile state on mount and on resize
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    // Parallax-like effect (optional)
    const handleScroll = () => {
        const scrollY = window.scrollY;

        const bg = document.querySelector(".layer-bg");
        if (bg) {
            if (scrollY === 0) {
                bg.style.transform = "";
            } else {
                bg.style.transform = `translateY(${scrollY * 0.5}px)`;
            }
        }

        const bgMob = document.querySelector(".layer-bg-mob");
        if (bgMob) {
            if (scrollY === 0) {
                bgMob.style.transform = "";
            } else {
                bgMob.style.transform = `translateY(${scrollY * 0.5}px)`;
            }
        }

        const bgMid = document.querySelector(".layer-mid");
        if (bgMid) {
            setTimeout(() => {
                if (scrollY === 0) {
                    bgMid.style.transform = "";
                } else {
                    bgMid.style.transform = `translateY(${scrollY * 0.5}px)`;
                }
            }, 1); // delay of 1ms
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll(); // run once on mount
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <>
            {/* Lightbox for your video */}
            {/* <FsLightbox toggler={toggler} sources={["https://youtu.be/MHhIzIgFgJo"]} /> */}

            <section className="banner banner--style1">
                {/* Mobile layers */}
                <div className="d-block d-sm-none mob-layers">
                    <div className="layer-bg-mob">
                        <Image
                            src="/images/hero/background-small.webp"
                            alt="Background"
                            width={640}
                            height={400}
                            loading="eager"
                        />
                    </div>
                    <div className="layer-mid-mob">
                        <Image
                            src="/images/hero/midground-small.webp"
                            alt="Mountain"
                            width={640}
                            height={400}
                            loading="lazy"

                        />
                    </div>
                    <div className="layer-fore-mob">
                        <Image
                            src="/images/hero/foreground-small.webp"
                            alt="Bull"
                            width={640}
                            height={400}
                            loading="lazy"

                        />
                    </div>
                </div>

                {/* Desktop layers */}
                <div className="d-none d-sm-block">
                    <div className="layer-bg">
                        <Image
                            src="/images/hero/background-large.webp"
                            alt="Background"
                            width={1920}
                            height={1080}
                            style={{
                                width: '100%',
                                height: '100vh',
                                maxWidth: '100%'
                            }}
                            loading="eager"
                        />
                    </div>
                    <div className="layer-mid">
                        <Image
                            src="/images/hero/midground-large.webp"
                            alt="Mountain"
                            width={2048}
                            height={520}
                            loading="lazy"

                        />
                    </div>
                    <div className="layer-fore">
                        <Image
                            src="/images/hero/foreground-medium.webp"
                            alt="Bull"
                            width={2048}
                            height={1194}
                            loading="eager"

                        />
                    </div>
                </div>

                <div className="container hero-container" style={{ position: "relative", zIndex: 10000 }}>
                    <div className="banner__wrapper">
                        <div
                            className="row gy-5 gx-4"
                            style={{ justifyContent: "center" }}
                        >
                            <div className="col-lg-7 col-md-7 hero-row-mg order-1 order-md-2" style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                {/* Conditionally apply AOS attributes only when not on mobile */}
                                <div className="banner__content"  >
                                    <h1 className="banner__content-heading text-left">
                                        {t("heroTitleLine1", { Cross: "Cross", Chain: "Chain" })}
                                        <br />
                                        <span style={{ color: "white" }}>
                                            {t("heroTitleLine2")}
                                        </span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 hero-row-mg order-2 order-md-1">
                                <HeroWidget assets={assets} networks={networks} />
                            </div>
                            {/* Optionally, a second column with an image or anything else you want */}
                        </div>
                        <div
                            className="row gy-5 gx-4"
                            style={{ justifyContent: "center" }}
                        >
                            <div className="col-lg-7 col-md-7 hero-row-mg extraM" >
                                <p className="banner__content-moto text-center">
                                    {t("heroSubtitle", { non: "Non", custodial: "Custodial", multi: "Multi", blockchain: "Blockchain", DEX: "DEX" })}
                                </p>

                                <div className="banner__content-social text-center">
                                    <p>{t("heroSocialTitle")}</p>
                                    <ul className="social" style={{ justifyContent: "center" }}>
                                        <li className="social__item">
                                            <Link href="https://discord.gg/um6NazJSMk" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Discord">
                                                <Image src="/images/icon/discord.svg" alt="Discord icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://t.me/+TaTcoxpScgBbl4JJ" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Telegram">
                                                <Image src="/images/icon/telegram.svg" alt="Telegram icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://instaswap.medium.com/" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Medium">
                                                <Image src="/images/icon/medium.svg" alt="Medium icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://www.youtube.com/@instaswapdex" className="social__link social__link--style22" target="_blank" aria-label="Follow us on YouTube">
                                                <Image src="/images/icon/youtube.svg" alt="YouTube icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://x.com/instaswap_io" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Twitter">
                                                <Image src="/images/icon/X.svg" alt="X icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}