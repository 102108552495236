/* eslint-disable react/no-unescaped-entities */
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import getConfig from 'next/config';
import Image from "next/image";

function Services() {
    const { t } = useTranslation("home");
    const { publicRuntimeConfig } = getConfig();
    const basePath = publicRuntimeConfig.basePath || '';
    // Local array referencing translation keys for each service
    const servicesData = [
        {
            icon: "/images/business-icons/api-integration.png",
            titleKey: "partnersTools.apiTitle",
            descKey: "partnersTools.apiDesc",
            link: "api-integration"
        },
        {
            icon: "/images/business-icons/widget-integration.png",
            titleKey: "partnersTools.widgetTitle",
            descKey: "partnersTools.widgetDesc",
            link: "swap-widget"
        },
        {
            icon: "/images/business-icons/partners-portal.png",
            titleKey: "partnersTools.portalTitle",
            descKey: "partnersTools.portalDesc",
            link: "partners-portal"
        }
    ];

    return (
        <section className="service padding-top padding-bottom banner--style45">
            <div className="section-header section-header--max50">
                <h2 className="mb-15 mt-minus-5">
                    {/* Title from JSON */}
                    {t("partnersTools.sectionTitle")}
                </h2>
                {/* If you want a subtitle, uncomment below or add another key in JSON */}
                {/* <p>{t("partnersTools.sectionSubtitle")}</p> */}
            </div>

            <div className="container" style={{ position: "relative", zIndex: 1 }}>
                <div className="service__wrapper">
                    <div className="row g-4 align-items-center">
                        {servicesData.map((item, idx) => (
                            <div className="col-sm-6 col-md-6 col-lg-4" key={idx}>
                                <div
                                    className="service__item service__item--style1"
                                    data-aos="fade-up"
                                    data-aos-duration={800 + 200 * idx} // optional stagger
                                >
                                    <div className="service__item-inner text-center">
                                        <div className="service__item-thumb mb-30">
                                            <Image
                                                className="dark"
                                                src={item.icon}
                                                alt="service-icon"
                                                width={48}
                                                height={48}
                                            />
                                        </div>
                                        <div className="service-content">
                                            <h3 className="mb-15">
                                                <Link className="stretched-link" href={item.link}>
                                                    {t(item.titleKey)}
                                                </Link>
                                            </h3>
                                            <p className="mb-0">
                                                {t(item.descKey, { blockchains: "Blockchains", cross: "cross", Blockchain: "Blockchain", blockchain: "blockchain", non: "Non", chain: "Chain", custodial: "Custodial", multi: "multi", DEX: "DEX", Cross: "Cross", Chain: "Chain" })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* end of map */}
                    </div>

                </div>
            </div>

            <div className="feature__shape">
                <div style={{
                    backgroundImage: `url(${basePath}/images/others/bglandscape-stars-dark-small-square-low.webp)`,
                    backgroundSize: "auto",
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    opacity: 0.1
                }}></div>
            </div>
        </section>
    );
}

export default Services;
