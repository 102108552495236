export const useAttributeConverter = () => {
    const booleanToString = (value) => {
        if (typeof value === 'boolean') {
            return value ? 'true' : 'false';
        }
        return value;
    };

    return { booleanToString };
};
