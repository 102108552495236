import { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import Counter from "@/components/base/Counter";
import useTranslation from "next-translate/useTranslation";
import FsLightbox from "fslightbox-react";
import Image from 'next/image';
import { useAttributeConverter } from '@/hooks/useAttributeConverter';


function HowItWorks() {
    const { t } = useTranslation("home");
    const [activeView, setActiveView] = useState("first");
    const [toggler, setToggler] = useState(false);
    const [videoSource, setVideoSource] = useState([]);
    const { booleanToString } = useAttributeConverter();

    // Original steps list
    const steps = [
        {
            titleKey: "howItWorks.step1Title",
            descKey: "howItWorks.step1Desc",
            img: "/images/howto/connect-wallet-instaswap.png",
            rating: "images/feature/5.png",
            loanAmount: 10,
            video: "https://youtu.be/lY-PpqjJQMA"
        },
        {
            titleKey: "howItWorks.step2Title",
            descKey: "howItWorks.step2Desc",
            img: "/images/howto/choose-token.png",
            rating: "images/feature/6.png",
            loanAmount: 18,
            video: "https://youtu.be/P50lZkBijWM"
        },
        {
            titleKey: "howItWorks.step3Title",
            descKey: "howItWorks.step3Desc",
            img: "/images/howto/confirm-swap.png",
            rating: "images/feature/7.png",
            loanAmount: 30,
            video: "https://youtu.be/u3iKBfZ_IYo"
        },
        {
            titleKey: "howItWorks.step4Title",
            descKey: "howItWorks.step4Desc",
            img: "/images/howto/receive-crypto.png",
            rating: "images/feature/8.png",
            loanAmount: 28,
            video: "https://youtu.be/G0RqDXTfBa4"
        }
    ];

    // Alternate steps list (with same amount of items)
    const altSteps = [
        {
            titleKey: "howItWorksAlt.step1Title",
            descKey: "howItWorksAlt.step1Desc",
            img: "/images/howto/how-works-walletless-quote.png",
            rating: "images/feature/8.png",
            loanAmount: 12,
            video: "https://youtu.be/xUGDDzljbOA"
        },
        {
            titleKey: "howItWorksAlt.step2Title",
            descKey: "howItWorksAlt.step2Desc",
            img: "/images/howto/confirm-swap.png",
            rating: "images/feature/8.png",
            loanAmount: 20,
            video: "https://youtu.be/DlAzFZMS5QM"
        },
        {
            titleKey: "howItWorksAlt.step3Title",
            descKey: "howItWorksAlt.step3Desc",
            img: "/images/howto/send-funds-walletless.png",
            rating: "images/feature/8.png",
            loanAmount: 35,
            video: "https://youtu.be/5YNgGnYEDXA"
        },
        {
            titleKey: "howItWorksAlt.step4Title",
            descKey: "howItWorksAlt.step4Desc",
            img: "/images/howto/receive-crypto.png",
            rating: "images/feature/8.png",
            loanAmount: 30,
            video: "https://youtu.be/G0RqDXTfBa4"
        }
    ];

    // Choose the current steps based on the toggle state
    const currentSteps = activeView === "first" ? steps : altSteps;

    return (
        <>
            <FsLightbox toggler={toggler} sources={videoSource} />
            <section className="feature feature--style1 padding-bottom padding-top banner--style49">
                {/* Attractive Toggle Under Title */}
                <div className="toggle-view">
                    <div className="toggle-container">
                        <button
                            onClick={() => setActiveView("first")}
                            className={`toggle-btn ${activeView === "first" ? "active" : ""}`}
                        >
                            {t("howItWorks.wallet")}
                        </button>
                        <button
                            onClick={() => setActiveView("second")}
                            className={`toggle-btn ${activeView === "second" ? "active" : ""}`}
                        >
                            {t("howItWorks.walletless")}
                        </button>
                    </div>
                </div>
                <div className="container">
                    <div className="feature__wrapper">
                        <div className="row g-5 align-items-center justify-content-between">
                            <Tab.Container defaultActiveKey={0}>
                                {/* LEFT COLUMN (Titles / Step Names and Toggle) */}
                                <div className="col-md-6 col-lg-5 order-1 order-md-2 mt-12 mt-md-0">
                                    <div
                                        className="feature__content"
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                    >
                                        <div className="feature__content-inner">
                                            <div className="section-header">
                                                <h2 className="mb-15 mt-minus-5 text-center" >
                                                    {t("howItWorks.mainHeading")}
                                                </h2>
                                            </div>

                                            <div className="feature__nav">
                                                <Nav variant="pills" className="nav--feature nav--feature2">
                                                    {currentSteps.map((item, index) => (
                                                        <Nav.Link key={index} eventKey={index}>
                                                            <Nav.Item className="feature__item">
                                                                <h3 style={{ fontSize: '1rem', marginBottom: '0px', fontWeight: 600, marginInlineStart: '0px', marginInlineEnd: '0px', unicodeBidi: 'isolate', fontFamily: '"Open Sans", sans-serif' }}>{index + 1}. {t(item.titleKey)}</h3>
                                                            </Nav.Item>
                                                        </Nav.Link>
                                                    ))}
                                                </Nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* RIGHT COLUMN (Images / Tab Content) */}
                                <div className="col-md-6 col-lg-6">
                                    <div
                                        className="feature__thumb pt-5 pt-md-0"
                                        data-aos="fade-left"
                                        data-aos-duration="800"
                                    >
                                        <div className="feature__thumb-inner">
                                            <Tab.Content>
                                                {steps.map((item, index) => (
                                                    <Tab.Pane eventKey={index} key={index}>
                                                        {item.video && (
                                                            <div
                                                                className="floating-content__top-right--style2"
                                                                data-aos="fade-left"
                                                                data-aos-durnameation="1000"
                                                            >
                                                                <div className="floating-content__item--style2 text-center">
                                                                    <div
                                                                        onClick={() => {
                                                                            setVideoSource([item.video]);
                                                                            setToggler(!toggler);
                                                                        }}
                                                                        className="trk-btn trk-btn--outline22"
                                                                    >
                                                                        <Image src="/images/icon/youtube.svg" alt="YouTube icon" width={30} height={30} loading="lazy" />
                                                                        {t(item.titleKey)} {t('howItWorks.video')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="feature__image">
                                                            <div
                                                                className="about__thumb about__thumb--style2"
                                                                data-aos="fade-left"
                                                                data-aos-duration="800"
                                                            >
                                                                <div className="about__thumb-inner mt-30 mt-lg-0">
                                                                    <div className="about__thumb-image text-center">
                                                                        <Image src={item.img} alt={`Step ${index + 1} image`} className="dark" width={500} height={500} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Step description below image */}

                                                        <div style={{ marginTop: "1rem", whiteSpace: "pre-line", textAlign: "center", height: "100px" }}>
                                                            {t(item.descKey, { blockchain: "Blockchain", Blockchain: "Blockchain" })}
                                                        </div>
                                                    </Tab.Pane>
                                                ))}
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
                <div className="contact__shape">
                    <span className="contact__shape-item contact__shape-item--2" style={{ right: "-250px" }}> <span></span> </span>
                </div>
            </section>
        </>

    );
}

export default HowItWorks;
